import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import AuthContext from 'context/auth-context';
import { navigate } from 'gatsby';
import AAPLogo from 'images/aap-logo-black.svg';
import { MDXProvider } from '@mdx-js/react';
import SEO from 'components/seo';

const POSTS_REDIRECT = '/';

export default function Login({ path }: { path: string }) {
  const { setUser, isLoading, setIsLoading, magic } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch('/.netlify/functions/user');
        const { user, authorized } = await res.json();

        if (authorized) {
          authorized && setUser(user);
          setIsLoading(false);
          navigate(-1);
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const authenticateWithDb = async (DIDT: string) => {
    /* Pass the Decentralized ID token in the Authorization header to the database */
    try {
      const res = await fetch('/.netlify/functions/login', {
        method: 'POST',
        headers: new Headers({
          Authorization: 'Bearer ' + DIDT,
        }),
      });

      const data = await res.json();

      /* If the user is authorized, return an object containing the user properties (issuer, publicAddress, email) */
      /* Else, the login was not successful and return false */
      return data.authorized ? data.user : false;
    } catch (error) {
      setError('Error logging in');
      throw error;
    }
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch('/.netlify/functions/validate-user', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });

      const { isActiveMember } = await response.json();

      if (isActiveMember) {
        const DIDT = await magic.auth.loginWithMagicLink({ email });
        const user = DIDT && (await authenticateWithDb(DIDT));

        if (user) {
          setUser(user);
          setIsLoading(false);
          navigate(POSTS_REDIRECT);
        }
      } else {
        setError(
          "Invalid email. Is this the exact email address you used to sign up? (Heads up Gmail users - include the dots if you got 'em!"
        );
      }
    } catch (error) {
      setError('Error logging in');
      throw error;
    }
  };

  if (isLoading) return null;

  const components = {
    h1: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
      <h1
        className='mb-8 text-5xl font-bold text-gray-700 font-display'
        {...props}
      />
    ),
    p: (props: React.HTMLAttributes<HTMLParagraphElement>) => (
      <p className='text-lg text-gray-800 md:text-base' {...props} />
    ),
    a: (props: React.HTMLAttributes<HTMLAnchorElement>) => (
      <a
        className='text-orange-600'
        target='_blank'
        rel='noopener noreferrer'
        {...props}
      />
    ),
    hr: (props: React.HTMLAttributes<HTMLHRElement>) => (
      <hr className='' {...props} />
    ),
    ul: (props: React.HTMLAttributes<HTMLUListElement>) => (
      <ul className='pl-12 list-disc' {...props} />
    ),
    ol: (props: React.HTMLAttributes<HTMLOListElement>) => (
      <ol className='pl-12 list-decimal' {...props} />
    ),
    li: (props: React.HTMLAttributes<HTMLLIElement>) => (
      <li className='mb-2' {...props} />
    ),
    img: (props: React.HTMLAttributes<HTMLImageElement>) => (
      <img className='' {...props} />
    ),
  };

  return (
    <MDXProvider components={components}>
      <SEO title='Login' />
      <div className='flex items-center justify-center w-screen h-screen overflow-hidden bg-gradient-to-br from-orange-400 to-orange-500'>
        <form
          onSubmit={handleLogin}
          className='flex flex-col items-center max-w-sm p-4 space-y-4 bg-white rounded shadow-lg md:p-10 md:max-w-xl'
        >
          <AAPLogo className='h-10 mb-8 md:h-16' />
          <div>
            <h3 className='text-xl font-bold text-center'>Enter your email</h3>
            <h4 className='text-sm text-center'>
              (The one you used to sign up for The All Access Pass)
            </h4>
          </div>
          <input
            type='email'
            placeholder='name@email.com'
            className='w-full px-4 py-2 text-center bg-gray-100 border border-gray-400 rounded'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <button
            type='submit'
            className='w-full px-6 py-4 text-indigo-100 transition duration-100 bg-indigo-600 rounded cursor-pointer hover:bg-indigo-800'
          >
            Lemme in!
          </button>
          {error && (
            <span className='block text-sm text-center text-red-400'>
              {error}
            </span>
          )}
        </form>
      </div>
    </MDXProvider>
  );
}
